// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-gallery-companion-monuments-tsx": () => import("./../../../src/pages/gallery/companion-monuments.tsx" /* webpackChunkName: "component---src-pages-gallery-companion-monuments-tsx" */),
  "component---src-pages-gallery-etchings-tsx": () => import("./../../../src/pages/gallery/etchings.tsx" /* webpackChunkName: "component---src-pages-gallery-etchings-tsx" */),
  "component---src-pages-gallery-garden-art-tsx": () => import("./../../../src/pages/gallery/garden-art.tsx" /* webpackChunkName: "component---src-pages-gallery-garden-art-tsx" */),
  "component---src-pages-gallery-hmong-monuments-tsx": () => import("./../../../src/pages/gallery/hmong-monuments.tsx" /* webpackChunkName: "component---src-pages-gallery-hmong-monuments-tsx" */),
  "component---src-pages-gallery-individual-monuments-tsx": () => import("./../../../src/pages/gallery/individual-monuments.tsx" /* webpackChunkName: "component---src-pages-gallery-individual-monuments-tsx" */),
  "component---src-pages-gallery-jewish-monuments-tsx": () => import("./../../../src/pages/gallery/jewish-monuments.tsx" /* webpackChunkName: "component---src-pages-gallery-jewish-monuments-tsx" */),
  "component---src-pages-granite-colors-tsx": () => import("./../../../src/pages/granite-colors.tsx" /* webpackChunkName: "component---src-pages-granite-colors-tsx" */),
  "component---src-pages-guarantee-tsx": () => import("./../../../src/pages/guarantee.tsx" /* webpackChunkName: "component---src-pages-guarantee-tsx" */),
  "component---src-pages-history-tsx": () => import("./../../../src/pages/history.tsx" /* webpackChunkName: "component---src-pages-history-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-staff-tsx": () => import("./../../../src/pages/staff.tsx" /* webpackChunkName: "component---src-pages-staff-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-pages-why-choose-us-tsx": () => import("./../../../src/pages/why-choose-us.tsx" /* webpackChunkName: "component---src-pages-why-choose-us-tsx" */)
}

